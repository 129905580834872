var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile-spiral-drawing" },
    [
      _vm.isLandScape
        ? [
            _c(
              "div",
              { staticClass: "mobile-spiral-drawing__info" },
              [
                _vm.mission.instructions
                  ? _c("ResizableText", {
                      staticClass: "mobile-spiral-drawing__text",
                      attrs: { message: _vm.mission.instructions },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("Drawable", {
              ref: "drawable",
              staticClass: "mobile-spiral-drawing__canvas",
              attrs: {
                bgColor: "#fff",
                fill: "#fff",
                canDraw: "",
                height: _vm.height,
                width: _vm.width,
                lineWidth: _vm.lineWidth,
                alpha: 1,
                lineColorRgb: _vm.color,
                pen: _vm.pen,
                points: _vm.points,
              },
              on: { mouseUp: _vm.onMouseUp },
            }),
            _c("MobileDrawingPicker", {
              attrs: {
                sizes: false,
                brushSize: 2,
                hint: "Tap here to choose color or erase",
              },
            }),
            _vm.isViewerScribe
              ? _c("TwilioRoom", {
                  key: _vm.identity,
                  attrs: {
                    roomId: _vm.roomId,
                    userId: _vm.identity,
                    video: "",
                    constraints: _vm.constraints,
                  },
                })
              : _vm._e(),
          ]
        : _c("ResizableText", {
            staticClass: "mobile-spiral-drawing__text",
            attrs: { message: "Please turn phone to landscape mode to paint" },
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }