



















































import { defineComponent } from "@vue/composition-api"

import Drawable from "@/components/Drawable/Drawable.vue"
import ModeSlideTransition from "@/components/GroupTeams/Common/GameModes/ModeSlideTransition.vue"
import SlideFadeTransition from "@/components/GroupTeams/Common/Games/GameCardParts/SlideFadeTransition.vue"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import PlayLayout from "@/components/GroupTeams/Common/GameModes/PlayLayout.vue"
import UserMedia from "@/components/GroupTeams/Common/User/UserMedia.vue"
import TwilioRoom from "@/components/Twilio/TwilioRoom.vue"
import AppUserCard from "@/components/UserCard/AppUserCard.vue"
import MobileDrawingPicker from "@/components/GroupTeams/Mobile/MobileDrawingPicker.vue"

import useSpiralDrawing from "./useSpiralDrawing"

export default defineComponent({
  name: "SpiralDrawingMobile",
  components: {
    AppUserCard,
    UserMedia,
    SlideFadeTransition,
    ModeSlideTransition,
    PlayLayout,
    ResizableText,
    Drawable,
    TwilioRoom,
    MobileDrawingPicker
  },
  props: {
    isLandScape: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const {
      scribe,
      points,
      mission,
      color,
      pen,
      onMouseUp,
      drawable,
      width,
      height,
      identity,
      imageUrl,
      isViewerScribe,
      roomId,
      constraints,
      lineWidth
    } = useSpiralDrawing()

    return {
      scribe,
      points,
      mission,
      color,
      pen,
      onMouseUp,
      drawable,
      width,
      height,
      identity,
      imageUrl,
      isViewerScribe,
      roomId,
      constraints,
      lineWidth
    }
  }
})
